.mobileHeader {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    width: 100%;
    height: 109px;
    background-color: white;
    position: fixed;
    z-index: 100;
}

.logo {
    max-width: 100px;
    height: 100%;
    display: flex;
    align-items: center;
    img {
        max-height: 100px;
        max-width: 200px;
    }
}

.nav {
    position: fixed;
    opacity: 1;
    background: white;
    color: black;
    top: 109px;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    transform: translate(100%, 0);
    transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
    max-height: 150vh;
    overflow-y: scroll;
}

.nav__list {
    list-style: none;
    opacity: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 0;
    overflow-y: scroll;
}

.nav__item {
    color: inherit;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    font-size: 24px;
    text-decoration: none;
    text-transform: uppercase;
    // background-color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 65px;
    width: calc(100vw - 60px);
    border-bottom: 1px solid gray;

    a {
        height: 65px;
        width: 100%;
        border-bottom: 3px solid #eaeaea;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.nav__link:hover {
    color: gray;
}

.nav-toggle {
    padding: 0.5em;
    background: transparent;
    border: 0;
    cursor: pointer;
    position: relative;
    right: 0;
    top: 0;
    z-index: 1000;
}

.nav-open .nav {
    transform: translate(0%, 0);
}

.nav-open .nav-toggle {
    position: relative;
}

.nav-open .hamburger {
    transform: rotate(0.625turn);
}

.nav-open .hamburger::before {
    transform: rotate(90deg) translateX(-9px);
}

.nav-open .hamburger::after {
    opacity: 0;
}

.hamburger {
    display: block;
    position: relative;
}

.hamburger,
.hamburger::before,
.hamburger::after {
    background: black;
    width: 2em;
    height: 4px;
    border-radius: 1em;
    transition: transform 250ms ease-in-out;
}

.hamburger::before,
.hamburger::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
}

.hamburger::before {
    top: 9px;
}
.hamburger::after {
    bottom: 9px;
}

.mobileSubNav-item {
    background-color: #eaeaea;
    font-size: 18px;
    font-weight: 700;
    width: calc(100vw - 60px);
    height: 50px;
    &:hover {
        cursor: pointer;
    }

    a {
        color: black;
        text-decoration: none;
        width: calc(100vw - 60px);
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            color: black;
        }
    }
}

.mobileSubHeaderWrapper {
    width: 100%;
    z-index: 4;
    position: fixed;

    .mobileSubHeader {
        position: absolute;
        width: 100%;
        height: 50px;
        top: 109px;
        display: flex;
        list-style-type: none;
        justify-content: space-around;
        align-items: center;
        li {
            font-family: "Oswald", sans-serif;
            font-size: 18px;
            a {
                color: white;
                text-decoration: none;
            }
            
        }
    }    
}

@media (min-width: 900px) {
    .mobileHeader {
        display: none;
    }
    .mobileSubHeaderWrapper {
        display: none;
    }
}


