$thursdayJazzColor: rgba(245, 179, 85, 1);
$jeppisJazzFestivalColor: rgba(0, 117, 169, 1);
$jakobBigBandColor: rgb(179, 47, 47);
$font: "Oswald", sans-serif;
$fontSizeBody: 18px;

p {
    font-family: $font;
    font-size: $fontSizeBody;
}

h2,
h3,
h4 {
    font-family: $font;
}

h3,
h2 {
    font-size: 45px;
    font-weight: 600;
}

.concertBg {
    background-color: black;
    margin: 0;
}

.thursdayJazzBg {
    background-color: $thursdayJazzColor;
    margin: 0;
}

.jeppisJazzFestivalBg {
    background-color: $jeppisJazzFestivalColor;
    margin: 0;
}

.jakobBigBandBg {
    background-color: $jakobBigBandColor;
    margin: 0;
}

.thursdayJazzColor {
    color: $thursdayJazzColor;
}

.jeppisJazzFestivalColor {
    color: $jeppisJazzFestivalColor;
}

.jakobBigBandColor {
    color: $jakobBigBandColor;
}

.thursdayJazzContact {
    background-image: url("../src/Components/footerBackground.jpg");
    background-color: #cccccc;
    height: fit-content;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.jeppisJazzFestivalContact {
    background-image: url("../src/Components/footerBackground.jpg");
    background-color: #cccccc;
    height: fit-content;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.jakobBigBandContact {
    background-image: url("../src/Components/footerBackground.jpg");
    background-color: #cccccc;
    height: fit-content;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.homeContact {
    background-image: url("../src/Components/footerBackground.jpg");
    background-color: #cccccc;
    height: fit-content;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.jeppisJazzFestivalBannerBg,
.thursdayJazzBannerBg,
.jakobBigBandBannerBg,
.jeppisJazzBannerBg{
    background-image: url("../src/Components/jeppis_jazz_basic.jpg");
    background-color: #cccccc;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (min-width: 899px) {
    .jeppisJazzFestivalBannerBg,
    .thursdayJazzBannerBg,
    .jakobBigBandBannerBg,
    .jeppisJazzBannerBg {
        min-height: calc(50vh - 190px);
    }
}

li {
    a.jeppisJazzHeader {
        color: black;
        font-family: $font;
        font-weight: 700;
        // font-size: 1.5rem;
        text-transform: uppercase;
        text-align: center;
        width: 900px;
        &:hover {
            color: black;
        }
        &-active {
            background-color: black;
            color: white;
            font-family: $font;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            font-size: 1.4rem;
            &:hover {
                color: white;
            }
        }
    }
}

.thursdayJazzHeader {
    background-color: white;
    color: $thursdayJazzColor;
    font-family: $font;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    &:hover {
        color: $thursdayJazzColor;
    }

    &-active {
        background-color: $thursdayJazzColor;
        color: white;
        font-family: $font;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.4rem;
        transition: font-size 0.3s;

        &:hover {
            color: white;
        }
    }
}

.jeppisJazzFestivalHeader {
    background-color: white;
    color: $jeppisJazzFestivalColor;
    font-family: $font;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    &:hover {
        color: $jeppisJazzFestivalColor;
    }

    &-active {
        background-color: $jeppisJazzFestivalColor;
        color: white;
        font-family: $font;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.4rem;
        transition: font-size 0.3s;
        position: relative;
        &:hover {
            color: white;
        }
    }
}

.jakobBigBandHeader {
    background-color: white;
    color: $jakobBigBandColor;
    font-family: $font;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    &:hover {
        color: $jakobBigBandColor;
    }

    &-active {
        background-color: $jakobBigBandColor;
        color: white;
        font-family: $font;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        font-size: 1.4rem;
        transition: font-size 0.3s;
        &:hover {
            color: white;
        }
    }
}

// ================= HEADER ===================

.header {
    width: 100%;
    height: 109px;
    // background-color: black;

    .logo {
        display: flex;
        justify-content: space-around;
        // align-items: center;
        width: 900px;
        // background-color: red;
        z-index: 60;
        // margin-right: 9rem;

        img {
            // width: 300px;
            border-radius: 50%;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .headerMenu {
        display: flex;
        list-style-type: none;
        // width: fit-content;
        justify-content: center;
        margin: 0 auto;
        padding: 0;
        height: 109px;
        // font-size: 1.4rem;

        li {
            height: 100%;
            &:nth-of-type(2) {
                // border-left: 1px solid lightgray;
            }
            a {
                width: 234px;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                // border-right: 1px solid lightgray;
                // transition: 0.3s;
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0.5;
    }
    100% {
        opactiy: 1;
    }
}

.subHeader {
    width: 100%;
    height: 40px;
    // animation: fadeIn 0.3s;

    .subHeaderMenu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 40px;
        list-style-type: none;
        width: 650px;
        margin: 0 auto;

        li {
            margin-left: 35px;
            font-family: $font;
            font-size: 18px;
            &:hover {
                cursor: pointer;
            }
            a {
                text-decoration: none;
                color: white;
            }
        }
    }
}

.events {
    .categoryBanner,
    .jeppisJazzBanner {
        height: 374px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-height: 300px;
        }
    }
    .jeppisJazzBanner {
        background-color: black;
    }
}

@media (min-width: 899px) {
    .events {
        padding-top: 0;
    }
}

@media (max-width: 899px) {
    .header {
        display: none;
    }
    .subHeader {
        display: none;
    }
}

// ================= NEXT EVENT ===================

@keyframes slideIn {
    0% {
        opacity: 0;
        // padding-left: 1000px;
    }
    100% {
        opacity: 1;
        // padding-left: 0;
    }
}

// .events {
//     animation: slideIn 1s ease-out;
// }

.nextEvent {
    padding: 62px 0 0 0;
    text-align: center;
    transition: 1s;

    .nextEventTitle {
        padding-bottom: 62px;
        font-size: 30px;
        font-weight: bold;
    }

    .nextEventCard {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        margin-bottom: 50px;
        background-color: #eaeaea;
        width: 320px;
    }

    .nextEventCardImage {
        position: relative;

        .nextEventImg {
            width: 100%;
            height: 200px;
            object-position: top;
            object-fit: cover;
        }
        .imgCategory {
            position: absolute;
            bottom: 0;
            left: 0;
            font-size: $fontSizeBody;
            color: white;
            font-family: $font;
            font-weight: 600;
            letter-spacing: 1.8px;
            padding: 6px;
            z-index: 1;
        }
    }
    .nextEventCardText {
        height: 275px;
        width: 100%;
        position: relative;

        .nextEventCardDateAndTime {
            margin: 10px 0 0 0;
        }

        .nextEventCardPlace {
            margin: 0;
        }

        .nextEventCardDateAndTime,
        .nextEventCardPlace {
            font-size: $fontSizeBody;
            width: 100%;
            text-align: center;
        }

        .nextEventCardTitle {
            width: 100%;
            text-align: center;
            margin-top: 18px;
            font-size: 30px;
        }
        .nextEventCardButton {
            position: absolute;
            bottom: 30px;
            left: calc(50% - 61px);
            width: 122px;
            height: 31px;
            border: none;
            border-radius: 30px;
            background-color: black;
            color: white;
            text-transform: uppercase;
        }
    }
}

@media (min-width: 899px) {
    .nextEvent {

        .nextEventCard {
            margin: 0 auto;
            display: flex;
            flex-direction: row-reverse;
            justify-content: center;
            position: relative;
            margin-bottom: 100px;
            background-color: #eaeaea;
            max-width: 900px;
            width: 90%;
            height: 400px;
        }

        .nextEventCardImage {
            width: 150%;
            position: initial;
            .nextEventImg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .imgCategory {
                position: absolute;
                top: 0;
                bottom: 360px;
                left: 0;
                font-size: $fontSizeBody;
                color: white;
                font-family: $font;
                font-weight: 600;
                letter-spacing: 1.8px;
                padding: 6px;
                z-index: 1;
            }
        }
        .nextEventCardText {
            width: 700px;
            position: relative;
            padding: 0 20px;
            height: 100%;

            .nextEventCardDateAndTime {
                margin: 60px 0 0 0;
            }

            .nextEventCardPlace {
                margin: 0;
            }

            .nextEventCardDateAndTime,
            .nextEventCardPlace {
                font-size: $fontSizeBody;
                width: 100%;
                text-align: center;
            }

            .nextEventCardTitle {
                width: 100%;
                text-align: center;
                margin-top: 20px;
                font-size: 30px;
                font-weight: 600;
            }
            .nextEventCardButton {
                // margin: 0 auto 10px auto;
                width: 122px;
                height: 31px;
                border: none;
                border-radius: 30px;
                background-color: black;
                color: white;
                text-transform: uppercase;
            }
        }
    }
}

// ==============UPCOMING EVENTS================

.upComingEvents {
    // background-color: #eaeaea;
    background-color: white;
    padding-top: 64px;

    .upComingEventsTitle {
        font-size: 29px;
        width: 100%;
        text-align: center;
        margin: 0 0 64px 0;
        font-weight: bold;
    }

    .eventSmallCardContainer {
        width: 100%;

        .eventSmallCard {
            box-shadow: #00000029 0 3px 10px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            // width: fit-content
            width: 100%;
            margin-bottom: 50px;
            width: 300px;

            .eventSmallCardImage {
                position: relative;

                img {
                    width: 100%;
                    height: 200px;
                    object-position: top;
                    object-fit: cover;
                }
                .imgCategory {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    font-size: $fontSizeBody;
                    color: white;
                    font-family: $font;
                    font-weight: 600;
                    letter-spacing: 1.8px;
                    padding: 6px;
                }
            }
            .eventSmallCardText {
                height: 275px;
                width: 100%;
                background-color: white;
                position: relative;
                background-color: #eaeaea;


                .eventSmallCardDate {
                    margin: 10px 0 0 0;
                    padding: 0;
                }

                .eventSmallCardTime {
                    text-align: center;
                }

                .eventSmallCardPlace {
                    margin: 0;
                }

                .eventSmallCardDate,
                .eventSmallCardPlace {
                    font-size: $fontSizeBody;
                    width: 100%;
                    text-align: center;
                }

                .eventSmallCardTitle {
                    width: 100%;
                    max-width: 90%;
                    text-align: center;
                    margin: 20px auto 0 auto;
                    font-size: 20px;
                    padding-bottom: 62px;
                    font-weight: 600;
                }

                .infoButton {
                    position: absolute;
                    bottom: 30px;
                    left: calc(50% - 61px);
                    width: 122px;
                    height: 31px;
                    border: none;
                    border-radius: 30px;
                    background-color: black;
                    color: white;
                    text-transform: uppercase;
                }
            }
        }
    }
}

@media (min-width: 726px) {
    .upComingEvents {
        // background-color: #eaeaea;
        background-color: white;
        padding: 64px 20px;

        .upComingEventsTitle {
            font-size: 30px;
            width: 100%;
            text-align: center;
            margin: 0 0 64px 0;
            font-weight: bold;
        }

        .eventSmallCardContainer {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            justify-content: center;
            gap: 70px;
            max-width: 1100px;
            margin: 0 auto;

            .eventSmallCard {
                box-shadow: #00000029 0 3px 10px;
                margin: 0 auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;

                .eventSmallCardImage {
                    position: relative;

                    img {
                        width: 100%;
                        height: 200px;
                        object-fit: cover;
                    }
                    .imgCategory {
                        position: absolute;
                        // top: 0;
                        left: 0;
                        font-size: $fontSizeBody;
                        color: white;
                        font-family: $font;
                        font-weight: 600;
                        letter-spacing: 1.8px;
                        padding: 6px;
                    }
                }
                .eventSmallCardText {
                    height: 275px;
                    width: 100%;

                    position: relative;

                    .eventSmallCardDate {
                        margin: 10px 0 0 0;
                    }

                    .eventSmallCardPlace {
                        margin: 0;
                    }

                    .eventSmallCardDate,
                    .eventSmallCardPlace {
                        font-size: $fontSizeBody;
                        width: 100%;
                        text-align: center;
                    }

                    .eventSmallCardTitle {
                        width: 100%;
                        text-align: center;
                        margin-top: 20px;
                        font-size: 25px;
                        padding-bottom: 62px;
                        font-weight: 600;
                    }

                    .infoButton {
                        width: 122px;
                        height: 31px;
                        border: none;
                        border-radius: 30px;
                        background-color: black;
                        color: white;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

.showPost {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 30px 0 60px 0;
    padding: 60px 25px;
    max-width: 900px;
    .categoryBanner {
        height: 374px;
    }

    .showPost-a {
        margin-bottom: 20px;
        padding-right: 20px;
        .showPostCategory {
            width: fit-content;
            padding: 6px;
            color: white;
            font-size: $fontSizeBody;
            font-family: $font;
            font-weight: 700;
            letter-spacing: 1.8px;
            margin-bottom: 40px;
        }
        .showPostTitle {
            max-width: 90%;
            margin-bottom: 60px;
            font-weight: 700;
            font-size: 32px;
        }
        .showPostInfoText {
            font-size: $fontSizeBody;
        }
        .showPostInfoLink {
            overflow-wrap: break-word;
        }
        .iconWrapper {
            display: inline-block;
            width: 30px;
            text-align: center;
        }
        i {
            margin-right: 10px;
        }
    }
    .showPost-b {
        width: 100%;
        img {
            width: 100%;
            height: 300px;
            object-position: top;
            object-fit: cover;
            margin-bottom: 40px;
        }
        .showPostBTitle {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .showPostBDescription {
            font-size: $fontSizeBody;
            white-space: pre-wrap;
        }
        .bandMemberWrapper {
            font-size: 19px;

            .bandMemberName {
                font-weight: 700;
            }
            .bandMemberInstrument {
                font-size: $fontSizeBody;
            }
        }
    }
}

@media (max-width: 899px) {
    .showPost {
        flex-direction: column;
        width: 80%;
        margin: 0 auto;
        padding: 180px 5px 25px 5px;
        .showPost-a {
            width: 100%;
        }
        .showPost-b {
            width: 100%;
            img {
                max-width: 300px;
                height: 200px;
            }
        }
    }
}

@media (min-width: 900px) {
    .showPost {
        flex-direction: row;
        width: 900px;
        margin: 0 auto;
        .showPost-a {
            width: 50%;
            .showPostTitle {
                font-size: 36px;
            }
        }
        .showPost-b {
            width: 50%;
        }
    }
}

@media (min-width: 1152px) {
    .showPost {
        flex-direction: row;
        width: 1152px;
    }
    .showPost-a {
        width: 50%;
        .showPostTitle {
            font-size: 46px;
        }
    }
}

.admin {
    padding-top: 63px;
    margin-bottom: 50px;
}

@media (max-width: 900px) {
    .admin {
        padding-top: 110px;
    }
}

.contact {
    padding: 50px 25px 25px 25px;
    margin: 0;
    color: white;
    text-shadow: 0px 0px 5px black;

    .contactWrapper {
        display: flex;
        flex-direction: column;

        .contactTitle {
            font-size: 30px;
            text-transform: uppercase;
            font-weight: 600;
            text-align: left;
            padding: 0;
            margin-top: -4px;
            margin-bottom: 20px;
        }

        .someWrapper {
            width: 100%;
            margin-bottom: 30px;
            text-align: left;
        }

        .contactThursdayJazz,
        .contactJeppisJazzFestival,
        .contactJakobBigBand,
        .contactConcerts {
            margin-bottom: 20px;
            .title {
                font-size: $fontSizeBody;
                font-weight: 600;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            .contactGroup {
                margin-bottom: 40px;
                p {
                    margin: 0 0 5px 0;
                }
                .name {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
            .someGroup {
                .facebook,
                .instagram {
                    margin-right: 10px;
                    font-size: $fontSizeBody;
                }
            }
        }
    }
}

@media (min-width: 899px) {
    .contact {
        // padding: 50px 25px 25px 25px;
        display: flex;

        width: 100%;
        margin: 0 auto;
        margin: 0;

        .contactWrapper {
            width: 900px;
            flex-direction: row;
            justify-content: space-between;
            margin: 0 auto;

            .contactTitle {
                text-align: center;
                flex: 7;
                align-self: top;
            }

            .someWrapper {
                text-align: center;
            }

            .contactThursdayJazz,
            .contactJeppisJazzFestival,
            .contactJakobBigBand,
            .contactConcerts {
                margin-bottom: 30px;
                padding-left: 3%;
                // flex: 4;
                .title {
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 30px;
                }
                .contactGroup {
                    margin-bottom: 20px;
                    p {
                        margin: 0 0 5px 0;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}

.noEventsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    height: calc(100vh - 172px);
    .noEvents {
        font-size: 1.5rem;
        background-color: lightgray;
        color: black;
        padding: 10px;
    }
}

.membershipFormContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    
    @media(max-width: 900px){
        padding-top: 180px;
    }
    .membershipForm {
        width: 90%;
        max-width: 500px;
        background-color: white;
        padding: 30px;
        border-radius: 8px;
        .inputGroup {
            margin-bottom: 20px;

            .radioBtnWrapper {
                label {
                    display: inline;
                    margin-right: 5px;
                }
                input {
                    margin-right: 10px;
                }
            }

            label {
                display: block;
            }
            input[type="text"],
            input[type="email"] {
                border: none;
                border: 1px solid lightgray;
                border-radius: 8px;
                height: 40px;
                width: 100%;
                padding: 7px;
            }
        }
        button {
            border: none;
            border: 1px solid lightgray;
            border-radius: 8px;
            height: 40px;
            padding: 7px;
            width: 100%;
            margin: 0 auto;
        }
    }
}

.memberFormBg, .backlineBg {
    // background-color: lightgray;
    background-image: url("../src/Components/footerBackground.jpg");
    background-color: #cccccc;
    height: fit-content;
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
